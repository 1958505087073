@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Quicksand:wght@300..700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,400;1,700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Anton&family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}
html {
  scroll-behavior: smooth;
}
body{
		font-family: Quicksand;	
}


ul {
	list-style: none;
}

h1 {
	font-size: 3rem;
}

h2 {
	font-size: 2rem;
}

h3 {
	font-size: 1.5rem;
}
h4 {
	font-size: 1.2rem;
}

:root {
	--padding-inline-section: 20px;
	--primary-color: #dce775,
	--primary-dark-color: #d6dd52,
  --secondary: #4dd0e1,
  --secondary-dark: #45c0d0,
  --heading-color: #013d8e,
  --text-color: #3f3e3e,
  }

.container {
	max-width: 1300px;
	margin-inline: auto;
	padding-inline: var(--padding-inline-section);
	overflow: hidden;
}

.shadow {
	-webkit-box-shadow: 0px 0px 5px 2px rgba(196,196,196,1);
	-moz-box-shadow: 0px 0px 5px 2px rgba(196,196,196,1);
	box-shadow: 0px 0px 5px 2px rgb(223, 223, 223);
}

.termFont {
	font-family: Poppins !important;
}


.cookieScrollbar::-webkit-scrollbar {
  display: none;
}

.customHorizontalScroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(183, 183, 183, 0.2);
  border-radius: 10px;
}

/* Custom scrollbar styling for Webkit browsers */
.customHorizontalScroll::-webkit-scrollbar {
  height: 6px;
  background-color: #f5f5f5;
  border-radius: 10px;
}

/* Custom scrollbar thumb styling */
.customHorizontalScroll::-webkit-scrollbar-thumb {
  background-color: #bababa;
  border-radius: 10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

@property --animate-duration {
  syntax: '<angle>';
  initial-value: 0deg;
  inherits: false;
}
@property --Color {
  syntax: '<color>';
  initial-value: #4afd71;
}
 
@keyframes rotate {
  0% {
    --animate-duration: 0deg;
    --Color: #4dd0e1;
  }
  100% {
    --animate-duration: 360deg;
    --Color: #4dd0e1;
  }
}
 
.clamBtn:after,
.clamBtn:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  height: 100%;
  border-radius: calc(100px + 1.5px);
  background-size: 100% 100%;
  background-position: 0px 0px;
  background-image: conic-gradient(
    from var(--animate-duration) at 50% 50%,
    #fbb245 0%,
    rgb(116, 242, 87) 20%,
    #b2f339 40%,
    rgb(245, 144, 167) 60%,
    rgb(239, 192, 4) 80%,
    #f2c261 100%
  );
  animation: rotate 2s infinite linear;
}
 
.clamBtn:before {
  animation: rotate 2s infinite linear;
  filter: blur(20px);
}

.hideScrollBar::-webkit-scrollbar {
  display: none;
}