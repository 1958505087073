.heroCircle {
	-webkit-box-shadow: 0px 0px 25px 0px rgba(184, 184, 184, 0.5);
	-moz-box-shadow: 0px 0px 25px 0px rgba(184, 184, 184, 0.6);
	box-shadow: 0px 0px 25px 20px rgba(184, 184, 184, 0.5);
}

/* about */

.aboutMain {
	position: relative;
	width: 100%;
	height: 600px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.circle {
	position: absolute;
	width: 350px;
	height: 350px;
	border-radius: 50%;
}

.center-circle {
	width: 420px;
	height: 420px;
	z-index: 2;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 40px;
	box-sizing: border-box;
	text-align: center;
}

.title {
	font-size: 32px;
	color: #333;
	margin-bottom: 20px;
	font-weight: bold;
}

.content {
	font-size: 16px;
	color: #666;
	line-height: 1.6;
	margin-bottom: 30px;
}

/* Added media query for better responsiveness */
@media (max-width: 1200px) {
	.left-circle {
		left: 5%;
	}
	.right-circle {
		right: 5%;
	}
}

@media (max-width: 900px) {
	.circle {
		width: 300px;
		height: 300px;
	}
	.center-circle {
		width: 350px;
		height: 350px;
	}
}
